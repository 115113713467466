<template>
  <div class="grey lighten-5 q-pa-md ">
    <div class="row justify-between">
      <div class="col-4">
        <p class="text-h6">DETAILS ON DEVICES & CUSTOMERS</p>
      </div>
	    <div class="col-8">
        <div class="row q-mr-lg offset-md-1 justify-end">
          <div class="col-3 q-mr-md">
            <q-select color="bmx-blue" :options="statusList" label="Instrument FT Status" v-model:model-value="selectedStatus" multiple
              outlined dense>
	            <template v-slot:option="{options, itemProps, opt, selected, toggleOption }">
		            <q-item v-bind="itemProps" style="padding: 0">
			            <q-item-section side style="padding-right:0">
				            <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
			            </q-item-section>
			            <q-item-section>
				            <q-item-label v-html="opt" />
			            </q-item-section>
		            </q-item>
	            </template>
            </q-select>
          </div>
	        <div class="col-3 q-mr-md">
		        <q-select color="bmx-blue" :options="badSpottingFilter" label="Bad Spotting Alert" v-model:model-value="selectedBadSpotting" multiple
		                  outlined dense>
			        <template v-slot:option="{options, itemProps, opt, selected, toggleOption }">
				        <q-item v-bind="itemProps" style="padding: 0">
					        <q-item-section side style="padding-right:0">
						        <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
					        </q-item-section>
					        <q-item-section>
						        <q-item-label v-html="opt" />
					        </q-item-section>
				        </q-item>
			        </template>
		        </q-select>
	        </div>
	        <div class="col-3 q-mr-md">
		        <q-select color="bmx-blue" :options="RECOMMENDED_DATE_FILTER_VALUE" label="FT Recommended date" v-model:model-value="selectRecommendedDate" multiple
		                  outlined dense>
			        <template v-slot:option="{options, itemProps, opt, selected, toggleOption }">
				        <q-item v-bind="itemProps" style="padding: 0">
					        <q-item-section side style="padding-right:0">
						        <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
					        </q-item-section>
					        <q-item-section>
						        <q-item-label v-html="opt" />
					        </q-item-section>
				        </q-item>
			        </template>
		        </q-select>
	        </div>
        </div>
      </div>
    </div>
    <div class="q-pa-md">
      <q-table :rows="instrumentList" :columns="columns" row-key="name" :sort-method="customSort" binary-state-sort>
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th v-for="col in props.cols" :key="col.name" :props="props" class="tableHeader">
              {{ col.label }}
            </q-th>
          </q-tr>
        </template>

        <template v-slot:body="props">
          <q-tr style="cursor: pointer" :props="props">
            <q-td @click="editItem($event, props.row)">
              <div>{{ orNA(props.row.instrument_id) }}</div>
            </q-td>
	          <q-td @click="editItem($event, props.row)">
              <div>{{ orNA(props.row.instrument_type) }}</div>
            </q-td>
	          <q-td @click="editItem($event, props.row)">
              <div>{{ orNA(props.row.region) }}</div>
            </q-td>
	          <q-td @click="editItem($event, props.row)">
              <div style="text-wrap : balance">{{ orNA(props.row.customer_name) }}</div>
            </q-td>
	          <q-td @click="editItem($event, props.row)">
              <div>{{ props.row.number_of_case ? props.row.number_of_case : '0'}}
	              {{ props.row.number_of_case ? props.row.number_of_case > 1 ? ' cases' : ' case' : ' case'}}</div>
            </q-td>
	          <q-td @click="editItem($event, props.row)">
              <div class="text-center flex items-center" style="gap:5px">
                <Days :date="props.row.last_mzml_information" />
              </div>
            </q-td>
	          <q-td @click="editItem($event, props.row)">
              <Status :status="props.row.instrument_status" type="ft_status" />
            </q-td>
	          <q-td @click="editItem($event, props.row)">
              <div class="row text-body2" style="gap: 5px">
                <Days :date="props.row.last_zip_communication" />
              </div>
            </q-td>
	          <q-td @click="editItem($event, props.row)">
		          <div v-if="!isVitekMSPrime(props.row.instrument_type)">
			          {{ props?.row?.recommended_ft_date ? props?.row?.recommended_ft_date?.split("T")[0] : null }}
		          </div>
		          <div v-else>
			          N/A
		          </div>
	          </q-td>
	          <q-td @click="editItem($event, props.row)">
		          <div  v-if="!isVitekMSPrime(props.row.instrument_type)" class="text-center flex items-center" style="gap:5px" >
			          <RemainingDays :date="props.row.recommended_ft_date" />
		          </div>
		          <div v-else>
			          N/A
		          </div>
	          </q-td>
	          <q-td @click="editItem($event, props.row)">
		          <Status   v-if="!isVitekMSPrime(props.row.instrument_type)" :status="props?.row?.bad_spotting_level" type="bad_spotting"  />
		          <div v-else>
			          N/A
		          </div>
	          </q-td>
            <q-td>
              <q-btn @click="addFeedback(props.row)" icon="mdi-comment-text"
                round color="bmx-blue" />
            </q-td>
            <q-td>
              <q-btn @click="redirectToVilinkPage(props.row)" icon="mdi-link-variant" color="bmx-blue" round />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>

  <DetailedInstrument v-if="store.getters.getIsFeedbackDialogOpen" :item="selectedItem" />
  <Comment v-if="store.getters.getIsCommentDialogOpen" :instrumentID=selectedInstrumentId />
  <FineTuningdate v-if="store.getters.getIsFineTuningDateDialogOpen"/>
</template>

<script setup>
import { useStore } from "vuex";
import {computed, ref, watch} from "vue"
import Comment from "./Comment.vue";
import Status from "./utils/Status.vue";
import Days from "./utils/Days.vue";
import RemainingDays from "@/components/utils/RemainingDays.vue";
import DetailedInstrument from "@/components/DetailedInstrument.vue";
import {useRoute} from 'vue-router';
import FineTuningdate from "@/components/FineTuningdate.vue";
import {
	VILINK_SERVER_URL,
	TABLE_HEADER_TO_DATA_KEY,
	RECOMMENDED_DATE_FILTER_VALUE,
	VITEK_PRIME,
	BAD_SPOTTING_NUMBERS
} from "@/const";
const store = useStore();
const route = useRoute();
const selectedInstrumentId = ref("")
const statusList = computed(() => store.getters.getStatusList);
const columns = computed(() => store.getters.getDataTableColumns);
const instrumentList = computed(() => store.getters.getFilteredInstrumentList)
const userToken = computed(() => store.getters.getUserToken)
const badSpottingFilter = ref([])

watch(
		() => route,
		() => {
			if (route.path !== "/") {
				if (route.path === "/EquipmentView" && route.query.token) {
					store.commit("setCustomerToken", route.query.token)
					store.dispatch("getInstrumentIdFromToken")
					store.dispatch("getLastUpdateDate")
					store.dispatch("setDefaultSelectedDate")
				}
			}
		},
		{
			deep:true
		}
);

watch(userToken, (newValue, oldValue) => {
	if (newValue !== oldValue) {
		store.commit("setIsCustomerVue", false)
		store.dispatch("getFilteredInstrumentList")
		store.dispatch("getFiltersPage")
		store.dispatch("setDefaultSelectedDate")
		store.dispatch("getLastUpdateDate")
	}
});

watch(instrumentList, (newValue) => {
	if (selectedBadSpotting.value.length !== 0) {
		return ;
	}
	let spottingAlert = []
	for (const instrument of newValue) {
		if (instrument.bad_spotting_level !== null) {
			spottingAlert.push(instrument.bad_spotting_level);
		}
	}
	spottingAlert = [...new Set(spottingAlert)]
	spottingAlert = spottingAlert.map((alert) => {
		return BAD_SPOTTING_NUMBERS[alert].title
	})
	badSpottingFilter.value = spottingAlert
});

const selectedItem = computed({
  get() {
    return store.getters.getSelectedItem
  },
  set(value) {
    store.commit("setSelectedItem", value);
  }
})

const selectedStatus = computed({
  get() {
    return store.getters.getSelectedStatus
  },
  set(value) {
    store.commit("setSelectedStatus", value)
    store.dispatch("filterDataTable")
  }
})

const selectedBadSpotting = computed({
	get() {
		return store.getters.getSelectedBadSpotting
	},
	set(value) {
		store.commit("setSelectedBadSpotting", value)
		store.dispatch("filterDataTable")
	}
})

const selectRecommendedDate = computed({
	get() {
		return store.getters.getSelectedRecommendedDate
	},
	set(value) {
		store.commit("setSelectedRecommendedDate", value)
		store.dispatch("filterDataTable")
	}
})
const editItem = (e, item) => {
	selectedItem.value = item
	store.dispatch("getDetailedInstrumentData", {"id" : item.Instrument});
	store.dispatch("getLastInstrumentComment", {});
	store.dispatch("getLastFTDateFromID", {"id" : item.instrument_id})
	store.commit("setIsFeedbackDialogOpen", true);
}

const addFeedback = (item) => {
	selectedInstrumentId.value = item.instrument_id
	store.commit("setIsCommentDialogOpen", true);
}

const redirectToVilinkPage = (item) => {
	let domain = item.vilink_server.includes(`EU`) ? VILINK_SERVER_URL.EU : item.vilink_server.includes(`US`) ? VILINK_SERVER_URL.US : VILINK_SERVER_URL.CN
  window.open(`${domain}${VILINK_SERVER_URL.URL}${item.equipment_id}`, "_blank")
}

const customSort =  (rows, sortBy, descending) => {
	let key = TABLE_HEADER_TO_DATA_KEY[sortBy];
	let result = [...rows];
	result.sort((a, b) => {
		if ([ "last_mzml_information", "last_zip_communication", "recommended_ft_date" ].includes(key)) {
			if (descending) {
				return new Date(b[key]) - new Date(a[key])
			} else {
				return new Date(a[key]) - new Date(b[key])
			}
		} else if ([ "number_of_case", "remaining_days" ].includes(key)) {
			if (descending) {
				return a[key] - b[key]
			} else {
				return b[key] - a[key]

			}
		} else {
			if (descending) {
				return b[key] > a[key] || b[key] === null ? -1 : 1
			} else {
				return a[key] > b[key] || a[key] === null ? -1 : 1
			}
		}
	})
	return result
}

const isVitekMSPrime = (instrumentType) => {
	return instrumentType === VITEK_PRIME
}

const orNA = (value) => {
	return value ? value : "N/A"
}

</script>
<style scoped>
	.tableHeader {
		background: rgb(0, 66, 127);
		color: rgb(255 255 255);
		text-wrap:balance
	}

	.q-table th {
		padding: 7px 10px;
	}

</style>