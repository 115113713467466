import moment from "moment";

export const VITEK_PRIME = "VITEK MS PRIME"
export const VITEK_MS = "VITEK MS"
export const FT_DATE = "Fine Tuning date"
export const PREDICTION = "prediction"

export const TABLE_HEADER_TO_DATA_KEY = {
	"Customer_Name" : "customer_name",
	"Region" : "region",
	"Vitek MS type" : "instrument_type",
	"Instrument" : "instrument_id",
	"Number of case" : "number_of_case",
	"LAST_MZML_INFO" : "last_mzml_information",
	"FT_STATUS" : "instrument_status",
	"LAST_ZIP_COM" : "last_zip_communication",
	"RECOMMENDED_FT_DATE" : "recommended_ft_date",
	"REMAINING_DAYS" : "recommended_ft_date",
	"BAD_SPOTTING" : "bad_spotting_level",
}
export const DATA_TABLE_COLUMNS = [
	{
		name: "Instrument",
		align: "left",
		label: "Instrument ID",
		field: "Instrument",
		sortable: true,
	},
	{
		name: "Vitek MS type",
		align: "left",
		label: "Vitek MS type",
		field: "Vitek_MS_Type",
		sortable: true,
	},
	{
		name: "Region",
		align: "left",
		label: "Region",
		field: "Region",
		sortable: true,
	},
	{
		name: "Customer_Name",
		align: "left",
		label: "Customer Name",
		field: "Customer_Name",
		sortable: true,
	},
	{
		name: "Number of case",
		align: "left",
		label: "Nb of case",
		field: "FT_CASE_ID_OR_NUMBER",
		sortable: true,
	},
	{
		name: "LAST_MZML_INFO",
		align: "left",
		label: "Last MZML information",
		field: "LAST_MZML_INFO",
		sortable: true,
	},
	{
		name: "FT_STATUS",
		align: "left",
		label: "Instrument FT Status",
		field: "FT_STATUS",
		sortable: true,
	},
	{
		name: "LAST_ZIP_COM",
		align: "left",
		label: "Last ZIP communication",
		field: "LAST_ZIP_COM",
		sortable: true,
	},
	{
		name: "RECOMMENDED_FT_DATE",
		align: "left",
		label: "FT Recommended Date",
		field: "FT_RECOCOMMENDED_DATE",
		sortable: true,
	},
	{
		name: "REMAINING_DAYS",
		align: "left",
		label: "Remaining Days",
		field: "FT_RECO_REMAINING_DAYS",
		sortable: true,
	},
	{
		name: "BAD_SPOTTING",
		align: "left",
		label: "Bad Spotting Alert",
		field: "BAD_SPOTTING",
		sortable: true,
	},
	{
		name: "display_feedback_button",
		align: "left",
		label: "Comment",
		field: "display_feedback_button",
	},
	{
		name: "Button",
		align: "left",
		label: "Vilink Link",
		field: "Region",
	},
]

export const RECOMMENDED_DATE_NUMBERS = {
	"overdue": {
		number : 0,
		title : "Overdue",
		color : "red"
	},
	"inNext3Days": {
		number : 0,
		title : "In the next 3 days",
		color : "orange"
	},
	"between3And7Days": {
		number : 0,
		title : "Between 3 and 7 days",
		color : "light-green"
	},
	"moreThanAWeek": {
		number : 0,
		title : "More than a week",
		color : "green"
	},
	"No recommended date": {
		number : 0,
	},
	"all": {
		number : 0,
	}
}

export const BAD_SPOTTING_NUMBERS = {
	"BAD_SPOTTING_AND_LOW_PEAKS_ALERTS": {
		number : 0,
		title : "Bad spotting and low peaks alerts",
		color : "red"
	},
	"LOW_PEAKS_ALERT": {
		number : 0,
		title : "Low peaks alert",
		color : "orange"
	},
	"BAD_SPOTTING_ALERT": {
		number : 0,
		title : "Bad spotting alert",
		color : "yellow"
	},
	"NO_ALERT": {
		number : 0,
		title : "No alert",
		color : "green"
	},
	"No bad spotting" : {
		number : 0,
	},
	"all": {
		number : 0,
	}
}

export const FILTERS_PAGE = [
	"region",
	"customer_name",
	"instrument_type",
	"instrument_ID",
	"instrument_status"
]

export const RECOMMENDED_DATE_FILTER_VALUE = [
	"Overdue",
	"More than a week",
	"In the next 3 days",
	"Between 3 and 7 days",
]

export const BAD_SPOTTING_FILTER_VALUE = [
	"Bad spotting and low peaks alerts",
	"Low peaks alert",
	"Bad spotting alert",
	"No alert",
]

export const FINE_TUNING_CASE_COLUMNS = [
	{
		name: "Instrument",
		align: "left",
		label: "Instrument",
		sortable: true,
	},
	{
		name: "FT_CASE_ID_OR_NUMBER",
		align: "left",
		label: "CASE_ID",
		sortable: true,
	},
	{
		name: "CREATION_DATE",
		align: "left",
		label: "Creation Date",
		sortable: true,
	},
	{
		name: "Subject",
		align: "left",
		label: "Subject",
		sortable: true,
	},
]

export const DETAILED_INSTRUMENT_HEADERS = [
	{
		name: "Instrument",
		key_name: "instrument_ID",
		align: "left",
		label: "Instrument ID"
	},
	{
		name: "FT_CASE_ID_OR_NUMBER",
		key_name: "case_number",
		align: "left",
		label: "Case ID"
	},
	{
		name: "CREATION_DATE",
		key_name: "creation_date",
		align: "left",
		label: "Creation Date"
	},
	{
		name: "Subject",
		key_name: "subject",
		align: "left",
		label: "Subject"
	},
	{
		name: "Category",
		key_name: "category",
		align: "left",
		label: "Category"
	}
]

export const VILINK_SERVER_URL = {
	"EU" : process.env.VUE_APP_VILINK_SERVER_URL_EU,
	"US" : process.env.VUE_APP_VILINK_SERVER_URL_US,
	"CN" : process.env.VUE_APP_VILINK_SERVER_URL_CN,
	"URL" : "/Thingworx/Runtime/index.html#mashup=VILINK.EquipmentDetails.Dashboard.Mashup&equipmentName="
}

export const CHART_CONFIG = {
	DATA_TYPE : {
		"VITEK MS" : ["Fine Tuning date", "Warning level", "Median of number of good peaks", "Median of number of peaks", "Median of number of good peaks prediction", "Median of number of peaks prediction"],
		"VITEK MS PRIME" : ["Fine Tuning date", "Warning level", "Median of MaxI", "Median of number of peaks"]
	},
	COLORS : {
		"Fine Tuning date" : "rgb(0,0,0)",
		"Warning level" : 'rgb(252,234,41)',

		"Median of number of good peaks" : "rgb(246,156,82)",
		"Median of number of good peaks - atcc" : "rgb(248,127,27)",
		"Median of number of good peaks - scal" : "rgb(250,198,158)",

		"Median of number of good peaks prediction - scal" : "rgba(255,0,255,0)",
		"Prediction scal - Median of number of good peaks" : "rgb(87,84,84)",
		"Median of number of good peaks prediction - atcc" : "rgba(255,0,255,0)",
		"Prediction atcc - Median of number of good peaks" : "rgb(152,147,147)",
		"Median of number of good peaks prediction" : "rgb(152,147,147)",

		"Median of number of peaks prediction" : "rgb(239,44,73)",
		"Median of number of peaks prediction - atcc" : "rgba(255,0,255,0)",
		"Prediction atcc - Median of number of peaks" : "rgb(239,44,73)",
		"Median of number of peaks prediction - scal" : "rgba(255,0,255,0)",
		"Prediction scal - Median of number of peaks" : "rgb(159,31,50)",

		"Median of number of peaks" : "rgb(44,82,239)",
		"Median of number of peaks - atcc" : "rgb(17,60,234)",
		"Median of number of peaks - scal" : "rgb(160,176,241)",

		"Median of MaxI" : "rgb(246,156,82)",
		"Median of MaxI - atcc" : "rgb(248,127,27)",
		"Median of MaxI var - scal" : "rgb(250,198,158)"
	},
	DATASET_CONFIG : { // Add label, data, backgroundColor and borderColor
		fill: false,
		borderWidth: 2,
		showLine: false,
		tension: 0.4
	},
	ANNOTATION_CONFIG : {
		warning : { // 	Add	yMin, yMax to define value
			type: 'line',
			name : "Warning level",
			borderWidth: 3,
			display: true,
			borderDash: [8, 4],
			borderColor: 'rgb(252,234,41)'
		},
		ft_date : { // Add value (as date format) to define value
			type: "line",
			name : "Fine Tuning date",
			mode: "vertical",
			display: true,
			scaleID: "x",
			borderColor: "rgb(0,0,0)",
		}
	},
	GLOBAL_CONFIG : { //put data and onCLick function
		type: 'line',
		data: null,
		options: {
			responsive: true,
			plugins: {
				legend: {
					position: 'bottom',

				},
				annotation: {
					annotations: []
				},
				tooltip: {
					callbacks: {
						footer : function(context) {
							if (context[0].raw.strain) {
								return `Strain : ${context[0].raw.strain}`
							} else if (context[0].raw.category) {
								return `Category : ${context[0].raw.category}`
							}
							return ""
						},
						label: function(context) {
							if (context.dataset?.label === "Fine Tuning date") {
								return `Fine Tuning date : ${moment(context?.parsed.x).format("DD-MM-YYYY")}`
							}
							return `${context?.dataset.label} : ${context?.parsed.y}`
						},
						title: function(context) {
							if (context[0]?.dataset?.label === "Fine Tuning date") {
								return ``
							}
							return `Date : ${moment(context[0].parsed.x).format("DD-MM-YYYY")}`
						}
					}
				}
			},
			scales: {
				x: {
					display: true,
					type : 'time',
					ticks: {
						source: 'data',
						autoSkip: true,
						autoSkipPadding: 150,
						maxTicksLimit: 10,
					},
					time : {
						unit : 'day',
						displayFormats: {
							day: 'DD - MMM'
						},
					},
					title: {
						display: true
					}
				},
				y: {
					display: true,
					title: {
						display: true,
						text: 'Value'
					}
				}
			}
		},
	},
	DEFAULT_WARNING_LEVEL : {
		"VITEK MS" : {
			"min" : 40,
			"max" : 70
		},
		"VITEK MS PRIME" : {
			"min" : 40,
			"max" : 65
		}
	}
}

export const TUTO_LINK = "https://biomerieux.sharepoint.com/sites/biomerieux-on-board-communities/CustomerService/SitePages/VITEK%C2%AE-MS-%26-PRIME---Fine-Tuning-Assistant.aspx"
export const API_URL = `https://${process.env.VUE_APP_AWS_API_DOMAIN}`;
